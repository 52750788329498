

.banner-2{
    background-image: url('https://i.ibb.co/7pB2PyG/10008.jpg');
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
}
.banner-3{
    background-image: url('https://i.ibb.co/cN5hB2B/10007.jpg');
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
}

.sidebanner1{
    background-image: url('https://i.ibb.co/23j4z7N/10004.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 180px;
}
.sidebanner2{
    background-image: url('https://i.ibb.co/hZw4HMG/10005.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 180px;
}
.sidebanner3{
    background-image: url('https://i.ibb.co/DrkDdJL/10006.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 180px;
}
.sidebanner4{
    background-image: url('https://i.ibb.co/XWTCgGB/10010.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 180px;
}
.sidebanner5{
    background-image: url('https://i.ibb.co/qy9t5n1/10011.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
}
.sidebanner6{
    background-image: url('https://i.ibb.co/61z5Vzp/10009.jpg');
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
}