

.reviw-bg{
    background-image: url('https://i.ibb.co/5s3Tgcg/bruno-van-der-kraan-VRERJ5-Mjp4c-unsplash.jpg');
    height: 550px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.733);
}

.review-text{
    color:whitesmoke;
    border-bottom: 3px solid #105cca;
    line-height: 50px;
}