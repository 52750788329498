@import url('https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@100;200&family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Noto+Sans:wght@900&family=Poppins:wght@700;800;900&family=Prata&family=Xanh+Mono:ital@0;1&display=swap');

.App {
  text-align: center;
}

body{
  font-family: 'Xanh Mono', monospace!important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}